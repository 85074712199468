import { loadMercadoPago } from "@mercadopago/sdk-js";
import * as bootstrap from 'bootstrap';

(function(window, document) {
  "use strict";

  /**
   * Easy selector helper function
   */
  const select = (el, all = false) => {
    el = el.trim()
    if (all) {
      return [...document.querySelectorAll(el)]
    } else {
      return document.querySelector(el)
    }
  }

  /**
   * Easy event listener function
   */
  const on = (type, el, listener, all = false) => {
    if (all) {
      select(el, all).forEach(e => e.addEventListener(type, listener))
    } else {
      select(el, all).addEventListener(type, listener)
    }
  }

  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'ARS'});

  // días de validez para hacer el pago
  const DAYS_TO_EXPIRE = 7
  const ONE_DAY_MS = 86400*1000
  // const PUBLIC_KEY = "TEST-ac692d6f-8535-498e-aed1-ac144a019d71"
  const PUBLIC_KEY = "APP_USR-1ef5474c-c5a9-42a2-adf8-ea02e390f0f6"
  // ACCESS_TOKEN = "APP_USR-3079388756172701-032218-a1c27dee96fe888866a8b49e948047b5-122346"
  // CLIENT_ID = "3079388756172701"
  // CLIENT_SECRET = "OKvEX9gDV5QLZlcfeY91fby35aPMsxed"

  function makeBackUrl(status) {
    let url = new URL(window.location.href)
    url.searchParams.set("status", status)
    url.hash = "checkout-comprobante"
    return url.href
  }

  const default_preference_params = {
    statement_descriptor : 'Todocodigos.ar',
    date_of_expiration: new Date(new Date().getTime()+DAYS_TO_EXPIRE*ONE_DAY_MS),
    expires: true,
    binary_mode: false,
    back_urls: {
      success: makeBackUrl("success"),
      pending: makeBackUrl("pending"),
      failure: makeBackUrl("failure"),
    }
  }


  let mp = null;
  async function getmp() {
    if (!mp) {
      await loadMercadoPago();
      mp = new window.MercadoPago(PUBLIC_KEY, {locale: 'es-AR'});
    }
    return mp;
  }
 
  function monitorearBotonMercadopago(containerId)
  {
    // Select the node that will be observed for mutations
    const targetNode = document.getElementById(containerId);

    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    const callback = function(mutationsList, observer) {
        for(const mutation of mutationsList) {
            if (mutation.type === 'childList') {
              for(const e of mutation.addedNodes) {
                if (e.classList && e.classList.contains('mercadopago-button')) {
                  e.classList.add('btn')
                  e.classList.add('btn-primary')
                  e.classList.remove('mercadopago-button')
                  const wmp = document.getElementById("waiting-mercadopago");
                  if (wmp) {
                    wmp.classList.add('hidden');
                  }
                }
              }
            }
        }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);

    // Later, you can stop observing
    // observer.disconnect();
  }

  async function createPreference(items, email, cuit, metadata)
  {
    try {
      const data = {
        ...default_preference_params,
        items: items,
        payer:
        {
          email: email,
          identification:
          {
            'type': (cuit.lenght==11) ? 'CUIT' : 'DNI',
            'number': cuit
          }
        },
        metadata: metadata,
      }
      console.log('POST preferences. body='+JSON.stringify(data))
      const response = await fetch('api/mercadopago/checkout/preferences', {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        method: "POST",
        cache: 'no-cache',
      })
      if (!response.ok) {
        const text = await response.text();
        console.error('[!response.ok] response.text='+text)
        throw new Error('No puedo conectarme con Mercadopago, reintente en unos minutos', {cause: text})
      }
      const preference = await response.json();
      if (preference.error) {
        console.error('[preference.error] preference='+JSON.stringify(preference))
        throw new Error('Error, intente nuevamente en unos minutos', {cause: preference})
      }
      return preference
    } catch (error) {
      console.error(error)
      throw error;
    }
  }

  async function updatePreference(preference, items, email, cuit, metadata)
  {
    try {
      let data = {
        ...default_preference_params,
        items: items,
        metadata: metadata,
      }
      if (!preference.payer || !preference.payer.email) {
        data.payer ||= {}
        data.payer.email = email
      }
      if (!preference.payer || !preference.payer.identification || !preference.payer.identification.number) {
        data.payer ||= {}
        data.payer.identification = {
              'type': (cuit.lenght==11) ? 'CUIT' : 'DNI',
              'number': cuit
            }
      }
      console.log('PUT preferences body='+JSON.stringify(data))
      const response = await fetch('api/mercadopago/checkout/preferences/'+preference.id, {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        method: "PUT",
        cache: 'no-cache',
      })
      if (!response.ok) {
        const text = await response.text();
        console.error('[!response.ok] response.text='+text)
        throw new Error('No puedo conectarme con Mercadopago, reintente en unos minutos', {cause: text})
      }
      preference = await response.json();
      if (preference.error) {
        console.error('[preference.error] preference='+JSON.stringify(preference))
        throw new Error('Error, intente nuevamente en unos minutos', {cause: preference})
      }
      return preference
    } catch (error) {
      console.error(error)
      throw error;
    }
  }

  async function getPreference(pref_id)
  {
    try {
      const response = await fetch('api/mercadopago/checkout/preferences/'+pref_id)
      if (!response.ok) {
        const text = await response.text();
        console.error('[!response.ok] response.text='+text)
        throw new Error('No puedo conectarme con Mercadopago, reintente en unos minutos', {cause: text})
      }
      const preference = await response.json();
      if (preference.error) {
        console.error('[preference.error] preference='+JSON.stringify(preference))
        throw new Error('Error, intente nuevamente en unos minutos', {cause: preference})
      }
      console.log('GET preferences. '+JSON.stringify(preference))
      return preference
    } catch (error) {
      console.error(error)
      throw error;
    }
  }

  async function onShowCheckoutModal(event) {
    // Google tag manager - Event snippet for Website traffic conversion page
    window.gtag('event', 'conversion', {'send_to': 'AW-975902916/hafaCNfu-dQDEMSxrNED'});

    // Button that triggered the modal
    let button = event.relatedTarget
    let sku = button.getAttribute('data-bs-sku')
    let precio = button.getAttribute('data-bs-precio')
    let product = await fetch('/api/producto/'+sku).then(response => response.json());
    let ean1 = await fetch('/api/producto/EAN1').then(response => response.json());
    // Update the modal's content.
    document.getElementById('checkout-desc1').textContent = 'Códigos EAN13';
    document.getElementById('checkout-desc1').title = sku
    document.getElementById('checkout-cant1').textContent = product.uxb;
    document.getElementById('checkout-pu1').textContent = formatter.format(ean1.precio);
    let parcial = product.uxb * ean1.precio;
    document.getElementById('checkout-importe1').textContent = formatter.format(parcial);
    let descuento = parcial - precio;
    if (descuento>=0.01) {
      document.getElementById('checkout-row2').classList.remove('hidden');
      document.getElementById('checkout-desc2').textContent = 'Descuento';
      document.getElementById('checkout-cant2').textContent = '';
      document.getElementById('checkout-pu2').textContent = '';
      document.getElementById('checkout-importe2').textContent = formatter.format(-descuento);
    } else {
      document.getElementById('checkout-row2').classList.add('hidden');
    }
    document.getElementById('checkout-total').textContent = formatter.format(precio);
    document.getElementById('checkout-total').setAttribute('data-value', precio);
    const wmp = document.getElementById("waiting-mercadopago");
    if (wmp) {
      wmp.classList.remove('hidden');
    }
    // estado inicial
    const showTabEvent = new Event('show.bs.tab');
    document.getElementById('checkout-datos-tab').dispatchEvent(showTabEvent)
    document.getElementById('checkout-btn-next').focus()
  }

  function onHideTab(event) {
    let ok, form, email1, email2;

    const tabId = event.target.id;
    switch (tabId) {
      case "checkout-datos-tab":
        ok = true;
        form = document.getElementById("checkout-datos-form")
        if (!form.checkValidity()) {
          ok = false;
        }
        email1 = document.getElementById('checkout-email')
        email2 = document.getElementById('checkout-email2')
        if (email1.value === email2.value) {
          email2.setCustomValidity('')
        } else {
          ok = false
          email2.setCustomValidity('Los emails no concuerdan')
        }
        if (!ok) {
          event.preventDefault()
        } else {
          Cookies.set('checkout-name', document.getElementById('checkout-name').value)
          Cookies.set('checkout-cuit', document.getElementById('checkout-cuit').value)
          Cookies.set('checkout-email', document.getElementById('checkout-email').value)
        }
        form.classList.add('was-validated')
        break
    }
  }

  function buttonEnable(btn, enable) {
    if (enable) {
      btn.removeAttribute('disabled')
    } else {
      btn.setAttribute('disabled','disabled')
    }
  }

  async function onShowTab(event) {
    const tabId = event.target.id;
    const btnNext = document.getElementById("checkout-btn-next")
    const btnPrev = document.getElementById("checkout-btn-prev")
    let preference
    let pref_id = Cookies.get('mp.preference.id')
    switch (tabId) {
      case "checkout-datos-tab":
        document.getElementById('checkout-name').value = Cookies.get('checkout-name') || ""
        document.getElementById('checkout-cuit').value = Cookies.get('checkout-cuit') || ""
        document.getElementById('checkout-email').value = Cookies.get('checkout-email') || ""
        document.getElementById('checkout-email2').value = document.getElementById('checkout-email').value
        buttonEnable(btnPrev, false)
        buttonEnable(btnNext, true)
        break
      case "checkout-resumen-tab":
        select('span[data-var="name"]').innerHTML = document.getElementById('checkout-name').value.trim() || '<b class="text-danger">desconocido</b>';
        select('span[data-var="cuit"]').innerHTML = document.getElementById('checkout-cuit').value.replace(/\D/g,'') || '<b class="text-danger">desconocido</b>';
        select('span[data-var="email"]').innerHTML = document.getElementById('checkout-email').value.trim() || '<b class="text-danger">desconocido</b>';
        buttonEnable(btnPrev, true)
        buttonEnable(btnNext, true)
        break
      case "checkout-pago-tab":
        {
          //elimino el boton de MercadoPago
          select('#cho-container').innerHTML=""
          const total_amount = Number(document.getElementById('checkout-total').getAttribute('data-value'))
          const pu = total_amount/Number(document.getElementById('checkout-cant1').textContent)
          let items = [{
            "title": document.getElementById('checkout-desc1').textContent,
            "quantity": Number(document.getElementById('checkout-cant1').textContent),
            "unit_price": pu, // Number(document.getElementById('checkout-pu1').textContent),
            "id": document.getElementById('checkout-desc1').title,  // sku
            "picture_url": new URL('assets/img/barcode-logo-blanco.png', window.location.href),
          }]
          const name = document.getElementById('checkout-name').value.trim()
          const cuit = document.getElementById('checkout-cuit').value.replace(/\D/g,'')
          const email = document.getElementById('checkout-email').value.trim()
          console.log('cookie mp.preference.id='+pref_id)
          console.log('items='+JSON.stringify(items))
          console.log('email='+email)
          console.log('cuit='+cuit)

          // email de notificacion, para saber que alguien está interesado
          const notif = {
            name: name,
            cuit: cuit,
            email: email,
            items: items,
            pref_id: pref_id,
            subject: "Intento de comprar codigos"
          }
          const response = await fetch('/api/contacto', {
            body: JSON.stringify(notif),
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
            },
            method: 'POST'})
          console.log('envie notificacion, resultado=', await response.text())
          const metadata = {
            name: name,
            cuit: cuit,
            email: email,
          }
          let pref_ok = false;
          if (!pref_id) {
            try {
              preference = await createPreference(items, email, cuit, metadata)
              pref_id = preference.id
              Cookies.set('mp.preference.id', preference.id)
              //document.getElementById("checkout-pago").textContent = 'Creacion Ok! preference.items='+JSON.stringify(preference.items)+"\n"+"preference.payer="+JSON.stringify(preference.payer)
              pref_ok = true;
            } catch (error) {
              // document.getElementById("checkout-pago-error").textContent = 'Error: '+error
            }
          } else {
            try {
              preference = await getPreference(pref_id)
              preference = await updatePreference(preference, items, email, cuit, metadata)
              pref_id = preference.id
              Cookies.set('mp.preference.id', pref_id)
              console.log('luego de update, preference='+JSON.stringify(preference))
              //document.getElementById("checkout-pago").textContent = 'Update Ok! preference.items='+JSON.stringify(preference.items)+"\n"+"preference.payer="+JSON.stringify(preference.payer)
              pref_ok = true;
            } catch (error) {
              // document.getElementById("checkout-pago-error").textContent = 'Error: '+error
            }
          }
          if (pref_ok) {
            let qs = 'subject=Pago realizado ' + preference.id + '&' + 'body=Adjunto comprobante de pago.';
            document.getElementById("checkout-informar-pago").href = "mailto:info@todocodigos.ar?" + qs;
            // vuelvo a crear el boton de MercadoPago
            let mp = await getmp();
            const checkout = mp.checkout({
               preference: {
                   id: pref_id // Indica el ID de la preferencia
               },
               render: {
                   container: '#cho-container',
                   label: 'Pagar con Mercadopago',
                }
            });
          }
          buttonEnable(btnPrev, true)
          buttonEnable(btnNext, false) // @@@ CORREGIR CUANDO SE HABILITE checkout-comprobante-tab
        }
        break
      case "checkout-comprobante-tab":
        preference = await getPreference(pref_id)
        document.getElementById("checkout-comprobante").textContent = JSON.stringify(preference)
        buttonEnable(btnPrev, true)
        buttonEnable(btnNext, false)
        break
    }
    // ajustar la altura cuando el contenido cambia dinámicamente
    bootstrap.Modal.getInstance(document.getElementById('checkoutModal')).handleUpdate()
  }

  function onPrev(event) {
    const currTabId = select('.active[data-bs-toggle="tab"]').id
    let newtab;
    switch (currTabId) {
      case "checkout-datos-tab":
        event.preventDefault();
        return;
      case "checkout-resumen-tab":
        newtab = "checkout-datos-tab"
        break
      case "checkout-pago-tab":
        newtab = "checkout-resumen-tab"
        break
      case "checkout-comprobante-tab":
        newtab = "checkout-pago-tab"
        break
    }
    document.getElementById(newtab).click();
  }

  function onNext(event) {
    const currTabId = select('.active[data-bs-toggle="tab"]').id
    let newtab;
    switch (currTabId) {
      case "checkout-datos-tab":
        newtab = "checkout-resumen-tab"
        break
      case "checkout-resumen-tab":
        newtab = "checkout-pago-tab"
        break
      case "checkout-pago-tab":
        event.preventDefault();
        return
        // @@@ cuando se habilite el tab
        // newtab = "checkout-comprobante-tab"
        // break
      case "checkout-comprobante-tab":
        event.preventDefault();
        return;
    }
    document.getElementById(newtab).click();
  }

  window.addEventListener('load', () => {
    const checkoutModal = document.getElementById('checkoutModal')
    checkoutModal.addEventListener('show.bs.modal', onShowCheckoutModal)
    on('hide.bs.tab', 'button[data-bs-toggle="tab"]', onHideTab, true)
    on('show.bs.tab', 'button[data-bs-toggle="tab"]', onShowTab, true)
    on('click', '#checkout-btn-prev', onPrev)
    on('click', '#checkout-btn-next', onNext)
    // ajustar el estilo del boton de Mercadopago
    monitorearBotonMercadopago('cho-container')
  });

})(window, document);

